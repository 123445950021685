$background: #6b778d;
$highlight2: #263859;
$header: #17223b;
$highlight: #ff6768;
$success: #66dd66;

html {
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}

html, body{
    height: 100%;
    background: $background;
    color: #fff;
    font-family: 'Roboto', sans-serif;
}

button, input[type=submit], .btn{
    background: $highlight;
    color: #fff;
    border: none;
    outline: none;
    padding: 0.5em 1em;
    border-radius: 3px;
    font-weight: 500;
    text-decoration: none;
    border: 1px solid rgba(0,0,0,0.2);

    &.btn-alt{
        background: #333;
        font-size: 0.85em;
    }

    &[disabled]{
        opacity: 0.5;
    }
    ~button, ~input[type=submit], ~.btn{
        margin-left: 0.5em;
    }
}

h2{
    margin: 0.75em 0 0.25em;
    color: #fff;
    text-align: center;
}

.Toastify{
    .Toastify__toast{
        font-family: inherit;
        font-weight: 500;
        padding-left: 1em;
    }
}

.players{
    display: inline-flex;
    flex-direction: column;
    width: auto;

    .player{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0.5em 0;
        width: auto;
        font-weight: bold;

        &.-gm{
            position: relative;
            &::before{
                display: inline;
                position: absolute;
                content: '👑';
                right: 100%;
                margin-right: 0.25em;
            }
        }

        &.-current{
            color: $success;
        }

        >span:first-of-type{
            font-size: 1.2em;
            flex: 1;
            ~*{
                margin-left: 1em;
            }
        }
    }
}

.actions{
    text-align: center;
    margin-top: 2em;
}

.status{
    opacity: 0.75;
}

.header{
    width: 100%;
    text-align: center;
    padding: 0.5em 0;
    background: $header;
    color: #fff;
    font-weight: bold;
    margin-bottom: 0.5em;
}

.reset-game{
    position: fixed;
    bottom: 0;
    right: 0;
    border-radius: 0;
}

.leave{
    margin-top: 2em;
}

.MainMenu{
    form{
        padding: 0 1em;
    }
    input{
        width: 100%;
        &:not([type=submit]){
            padding: 0.5em;
            border: 1px solid rgba(0,0,0,0.6);
            border-radius: 3px;
        }
    }
}

.StudyPhase{
    // Beware, iframe scroll in iOS and chrome works very differently
    .wikipedia-holder{
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll;
    }
    .wikipedia{
        border: none;
        width: 100%;
        min-height: 100%;

        &:not(.-open){
            display: none;
        }
    }

    .selected-topic{
        position: fixed;
        bottom: 0;
        margin: 0;
    }
}

.Lobby{
    display: flex;
    flex-direction: column;
    align-items: center;

    .make-gm{
        background: #eee;
        padding-right: 0.67em; // Fix for emoji not being centered
    }
}

.TopicSelection,
.StudyPhase{
    .gm{
        text-align: center;
        margin-top: 2em;
    }
}

.TopicSelection{
    display: flex;
    flex-direction: column;
    align-items: center;

    .gm,
    .topic-selector{
        width: 100%;
    }

    .topic-selector{
        padding: 1em;

        select{
            width: 100%;
            height: 2.5em;
        }
    }
}

.QuestionPhase{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.EndScreen{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    &.-wrong .result{
        background: $highlight;
    }
    &.-correct .result{
        background: $success;
        color: $header;
    }
    .answer{
        text-align: center;
        padding: 0 0.5em;
    }

    .score{
        color: $success;
    }
}